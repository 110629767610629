import kno from "../assets/airport/kno.jpg";
import pku from "../assets/airport/pku.jpg";
import pdg from "../assets/airport/pdg.jpg";
import plm from "../assets/airport/plm.jpg";
import cgk from "../assets/airport/cgk.jpg";
import hlp from "../assets/airport/hlp.jpg";
import kjt from "../assets/airport/kjt.jpg";
import srg from "../assets/airport/srg.jpg";
import yia from "../assets/airport/yia.jpg";
import soc from "../assets/airport/soc.jpg";
import sub from "../assets/airport/sub.jpg";
import dps from "../assets/airport/dps.jpg";
import lop from "../assets/airport/lop.jpg";
import upg from "../assets/airport/upg.jpg";
import mdc from "../assets/airport/mdc.jpg";
import bpn from "../assets/airport/bpn.jpg";
import pnk from "../assets/airport/pnk.jpg";
import djj from "../assets/airport/djj.jpg";
import lbj from "../assets/airport/lbj.png";

export const dataAirport = [
  {
    no: 1,
    code: "KNO",
    name: "Kualanamu International Airport",
    kota: "Medan",
    image: kno,
    url: "https://kualanamu-airport.co.id/",
  },
  {
    no: 2,
    code: "PKU",
    name: "Sultan Syarif Kasim II International Airport",
    kota: "Pekanbaru",
    image: pku,
    url: "https://sultansyarifkasim2-airport.co.id/",
  },
  {
    no: 3,
    code: "PDG",
    name: "Minangkabau International Airport",
    kota: "Padang",
    image: pdg,
    url: "https://minangkabau-airport.co.id/",
  },
  // {
  //   no: 4,
  //   code: "PLM",
  //   name: "Sultan Mahmud Badaruddin II International Airport",
  //   kota: "Palembang",
  //   image: plm,
  //   url: "https://smbadaruddin2-airport.co.id/",
  // },
  {
    no: 5,
    code: "CGK",
    name: "Soekarno–Hatta International Airport",
    kota: "Tangerang",
    image: cgk,
    url: "https://soekarnohatta-airport.co.id/",
  },
  {
    no: 6,
    code: "HLP",
    name: "Halim Perdanakusuma International Airport",
    kota: "Jakarta",
    image: hlp,
    url: "https://halimperdanakusuma-airport.co.id/",
  },
  {
    no: 7,
    code: "KJT",
    name: "Kertajati International Airport",
    kota: "Majalengka",
    image: kjt,
    url: "https://bijb.co.id/",
  },
  // {
  //   no: 8,
  //   code: "SRG",
  //   name: "Jenderal Ahmad Yani International Airport",
  //   kota: "Semarang",
  //   image: srg,
  //   url: "https://ahmadyani-airport.com/id",
  // },
  {
    no: 9,
    code: "YIA",
    name: "Yogyakarta International Airport",
    kota: "Kulon Progo",
    image: yia,
    url: "https://yogyakarta-airport.co.id/id",
  },
  // {
  //   no: 10,
  //   code: "SOC",
  //   name: "Adi Soemarmo International Airport",
  //   kota: "Surakarta",
  //   image: soc,
  //   url: "https://adisumarmo-airport.com/id",
  // },
  {
    no: 11,
    code: "SUB",
    name: "Juanda International Airport",
    kota: "Surabaya",
    image: sub,
    url: "https://juanda-airport.com/id",
  },
  {
    no: 12,
    code: "DPS",
    name: "I Gusti Ngurah Rai International Airport",
    kota: "Bali",
    image: dps,
    url: "https://bali-airport.com/id",
  },
  {
    no: 13,
    code: "LOP",
    name: "Lombok International Airport",
    kota: "Lombok",
    image: lop,
    url: "https://lombok-airport.co.id/id",
  },
  {
    no: 14,
    code: "UPG",
    name: "Sultan Hasanuddin International Airport",
    kota: "Makassar",
    image: upg,
    url: "https://hasanuddin-airport.co.id/id",
  },
  {
    no: 15,
    code: "MDC",
    name: "Sam Ratulangi International Airport",
    kota: "Manado",
    image: mdc,
    url: "https://samratulangi-airport.com/id",
  },
  {
    no: 16,
    code: "BPN",
    name: "Sultan Aji Muhammad Sulaiman International Airport",
    kota: "Balikpapan",
    image: bpn,
    url: "https://sepinggan-airport.com/id",
  },
  {
    no: 17,
    code: "LBJ",
    name: "Komodo International Airport",
    kota: "Labuan Bajo",
    image: lbj,
    url: "",
  },
  // {
  //   no: 18,
  //   code: "DJJ",
  //   name: "Sentani International Airport",
  //   kota: "Jayapura",
  //   image: djj,
  //   url: "https://sentani-airport.co.id/id",
  // },
];
